@import '~app/mixins';

.folders {
  $color-border: #e7ebf3;

  width: 325px;
  border: 1px solid $color-border;
  border-radius: 3px;
  background-color: $color-cyan;
  position: relative;
  padding: 7px 10px 15px;
  margin: 20px auto 0;

  .groupType {
    margin-top: 7px;
    margin-bottom: 15px;

    .input {
      height: 26px;
      width: 100%;
      border: 1px solid $color-grey;
      color: #9d9d9d;
      border-radius: 4px;
      background: $color-white;
      padding: 6px;
      outline: 0;
      font-size: 12px;

      &::-ms-expand {
        display: none;
      }
    }

    .label {
      margin-bottom: 3px;
      color: #395e96;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-around;
  }
}
