$color-blue-light: #4a90e2;
$color-blue-dark: #2b538f;
$color-blue-darkest: #395e96;
$color-blue-medium: #448afa;
$color-light-blue: #e3eeff;
$color-cyan: #f1f6ff;
$color-white: #fff;
$color-dirty-white: #f3f5f8;
$color-silver: #c4c4c4;
$color-alabaster: #f7f7f7;
$color-dove-gray: #6f6f6f;
$color-grey: #66707d;
$color-grey-1: #707986;
$color-grey-dark: #2b2c33;
$color-grey-light: #a3a8ad;
$color-grey-lighter: #e5e5e5;
$color-grey-smooth: #979797;
$color-filter-border: #6881a6;
$color-green: #109e2f;
$color-green-bright: #02a400;
$color-light-green: #e8f6eb;
$color-red: #d0021b;
$color-red-bright: #f00;
$color-border: #d0d2d5;
$color-teal: #128292;
$color-orange: #f16722;
$color-charcoal: #3C4654;
$dropdown-shadow: 0 0 10px 0 rgba(75, 75, 75, 0.5);

$left-side-width: 285px;

$transition-delay: 150ms;
$default-animation: ease-in;

$font-helvetica-neue: 'Helvetica Neue', Helvetica, sans-serif;
$font-inter: 'Inter';
