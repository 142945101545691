@import '~app/variables';

.caption {
  color: $color-grey;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  justify-content: space-around;
}

.add {
  width: 270px;
}

.trial {
  border-radius: 5px;
  border: 1px solid $color-red;
  color: $color-red;
  background-color: #fbcbc0;
  margin-top: 10px;
  padding: 6px;
  font-size: 13px;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
}

.remaining {
  font-size: 13px;
  color: $color-grey-light;
  margin-top: 10px;

  span {
    font-style: italic;
  }
}

.checks {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.groupWidget {
  $color-border: #e7ebf3;

  width: 325px;
  border: 1px solid $color-border;
  border-radius: 3px;
  background-color: $color-cyan;
  position: relative;
  padding: 7px 10px 15px;
  margin: 20px auto 0;

  .groupType {
    margin-top: 7px;
    margin-bottom: 15px;
  }

  .input {
    height: 26px;
    width: 100%;
    border: 1px solid $color-grey;
    color: #9d9d9d;
    border-radius: 4px;
    background: $color-white;
    padding: 6px;
    outline: 0;
    font-size: 12px;

    &::-ms-expand {
      display: none;
    }
  }

  &.andAdd {
    &:before,
    &:after {
      content: "";
      position: absolute;
      background: #f1f6ff;
      left: 50%;
    }

    &:before {
      top: -5px;
      width: 10px;
      height: 10px;
      margin-left: -5px;
      transform: rotate(45deg);
      box-shadow: 0 0 0 2px #e7ebf3;
    }

    &:after {
      height: 10px;
      top: 0;
      width: 20px;
      margin-left: -10px;
    }
  }
}

