@import '~app/variables';

.confirmPurchase {
  .header {
    font-size: 16px;
    line-height: 18px;
    padding-top: 10px;
    color: $color-grey;
    margin-bottom: 20px;
    padding-bottom: 6px;
    border-bottom: 1px solid $color-grey;
    font-weight: bold;
  }

  .message {
    color: $color-grey;
    margin-bottom: 10px;
    line-height: 24px;
  }

  .fields {
    label {
      margin: 12px 0 6px;
      font-size: 14px;
    }
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 25px;

    button {
      width: 100px;
    }
  }
}
